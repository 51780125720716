
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { Component, Input, OnInit } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Portfolio } from '@sei/ias-applications-lib-ux';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { FeeStrategy, ManagementFeeBreakdown, ProposalPercentFormat } from '../../model/account-fee';
import { CurrencyTypes, HUDSummaryLabel, PortfolioPurpose } from '../../model/enums';

@Component({
    selector: 'sei-car-fee-schedule-account-detail',
    templateUrl: './fee-schedule-account-detail.component.html',
    styleUrls: ['./fee-schedule-account-detail.component.scss'],
    providers: [PercentFormatPipe]

})
export class FeeScheduleAccountDetailComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public strategyFees: FeeStrategy[];

    @Input()
    public portfolios: Portfolio[];

    @Input()
    public managementFeeBreakdown: ManagementFeeBreakdown;

    public totalBalance: number;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    private displayPercentOptions: DisplayPercentOptions;
    public hudSummaryLabel: typeof HUDSummaryLabel = HUDSummaryLabel;
    public proposalPercentFormatOptions: ProposalPercentFormat;


    public get showDFS(): boolean {
        return (this.strategyFees.findIndex((strategy) => strategy.dfsFee && strategy.dfsFee > 0) >= 0);
    }

    constructor() {
        super('FeeScheduleAccountDetailComponent');
    }

    public ngOnInit(): void {
        const initialValue: number = 0;
        this.totalBalance = this.strategyFees.reduce(((accumulator, currentValue) => {
            return accumulator + currentValue.balance;
        }), initialValue);

        this.totalBalance = this.portfolios.reduce(((accumulator, currentValue) => {
            return accumulator + currentValue.fees.balance;
        }), this.totalBalance);

        this.totalBalance = Number(
            (Math.round(this.totalBalance * 100) / 100).toFixed(2)
        );

        this.displayPercentOptions = {
            displayPercentSymbol: true,
            roundToDecimal: 2,
            nullDisplay: DisplayNullOptions.BLANK
        };

        this.proposalPercentFormatOptions = {
            isFeeBreakdown: false,
            displayPercentOptions: this.displayPercentOptions
        };
    }

    public getExpense(expense: number, balance: number): number {
        const amountPercent = (expense / balance) * 100;
        const rateAmount = this.round(amountPercent, 6); // Note: Transform to 6 for floating point division issue

        return rateAmount;
    }

    public isClientDirected(portfolio: Portfolio): boolean {
        return portfolio.portfolioPurpose.portfolioPurposeId === PortfolioPurpose.ClientDirected;
    }

    public getTooltipMessage(): string {
        return TooltipConstants.CLIENT_DIRECTED_MESSAGE;
    }

    private round(value: number, precision: number): number {
        const specifiedDecimal = Math.pow(10, precision);
        return Math.round(value * specifiedDecimal) / specifiedDecimal;
    }
}
