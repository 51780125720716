/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { ContentTypeId, ContentTypeName, FileUploadViewMode, ProposalSectionLabel, SectionId } from '../../../model/enums';
import { PresentationBusinessRules, ProposalSection, ProposalStatement } from '../../../model/presentation';
import { DataSharingService } from '../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../service/presentation-setting.service';
import { ProposalService } from '../../../service/proposal.service';

@Component({
    selector: 'sei-car-proposal-section',
    templateUrl: './proposal-section.component.html'
})
export class ProposalSectionComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public set statement(value: ProposalStatement) {
        this._statement = _.cloneDeep(this._statement);
        this.changeDetectorRef.detectChanges();
        this._statement = value;
    }
    public get statement(): ProposalStatement {
        return this._statement;
    }

    @Input()
    public businessRules: PresentationBusinessRules;

    @Output()
    public savePdfStatement = new EventEmitter();

    @Output()
    public saveAdvisorContent = new EventEmitter<boolean>();

    @Output()
    public onDeleteSection: EventEmitter<string> = new EventEmitter();

    @Input()
    public proposalId: number;

    _statement: ProposalStatement;

    public showFileUploadModal: boolean = false;
    public fileUploadViewMode: FileUploadViewMode = FileUploadViewMode.AdvisorContent;
    public presentationType: string = 'Proposal';
    public tooltipText: string = 'The uploaded content will be saved as default content for future proposals.';

    public sectionName: string = '';
    public subSectionName: string = '';
    public isRenameSelected: boolean = false;

    constructor(
        private dataSharingService: DataSharingService,
        public presentationSettingService: PresentationSettingService,
        public route: ActivatedRoute,
        private proposalService: ProposalService,
        private changeDetectorRef: ChangeDetectorRef) {
        super('ProposalSectionComponent');
    }

    public ngOnInit(): void {

        if (this.fileUploadViewMode === FileUploadViewMode.AdvisorContent) {
            this.sectionName = 'Advisor Content';
        }

        this.subscriptions.push(this.dataSharingService.proposalSectionChanged.subscribe((response: ProposalSection) => {
            this.updateSections(response);
        }));

        if (this.statement && this.statement.sections) {
            this.statement.sections.map((section: ProposalSection) => {
                if (section.name === ProposalSectionLabel.CoverPage) {
                    section.isActive = true;
                    section.isCollapsed = false;
                } else {
                    section.isActive = false;
                    section.isCollapsed = true;
                    if (section.name === ProposalSectionLabel.ProposedScenario) {
                        this.sortProposalSection(section);
                    }
                    if (section.name === ProposalSectionLabel.AdvisorContent) {
                        this.sortBySortOrder(section);
                    }
                }
            });
        }
    }

    private sortBySortOrder(section: ProposalSection): void {
        section.sections.sort((a: ProposalSection, b: ProposalSection) => a.sortOrder - b.sortOrder);
    }

    public dropItem(event: CdkDragDrop<ProposalSection[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        event.container.data.forEach((section, index) => section.sortOrder = index);

        const parentSection = this.statement.sections.find((section) => section.id === +event.container.id);

        if (parentSection) {
            parentSection.sections = _.cloneDeep(event.container.data);
            this.dataSharingService.proposalSectionChanged.next(parentSection);
        }

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getConnectedList(): any[] {
        return this.statement.sections.map((x) => `${x.id}`);
    }

    public dropGroup(event: CdkDragDrop<ProposalSection[]>): void {
        if (event.previousIndex > 0 && event.currentIndex !== 0) {
            if (event.previousContainer === event.container) {
                moveItemInArray(
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex
                );
            } else {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex
                );
            }

            event.container.data.forEach((section, index) => section.sortOrder = index);
            this.statement.sections = _.cloneDeep(event.container.data);
        }
    }

    public updateSections(currentSection): void {
        this.statement.sections.forEach((section) => {
            if (section !== currentSection) {
                section.isActive = false;
            } else {
                section.isActive = true;
            }
        });
    }

    public onClickPreview(sectionGroup: ProposalSection): void {
        this.dataSharingService.proposalSectionChanged.next(sectionGroup);
    }

    public updatePreview(sectionGroup: ProposalSection): void {
        this.sortProposalSection(sectionGroup);
    }

    public onSavePdfStatement(event: string): void {
        this.savePdfStatement.emit(event);
    }

    public updateParentSection(event: string): void {
        if (event && this.statement && this.statement.sections) {
            const sectionToUpdate: string = event;
            const foundProposalSection: ProposalSection =
                this.statement.sections.find((proposalSection: ProposalSection) =>
                    proposalSection.name === sectionToUpdate
                );
            if (foundProposalSection) {
                this.dataSharingService.proposalSectionChanged.next(foundProposalSection);
            }
        }

    }

    private sortProposalSection(sectionGroup: ProposalSection): void {
        if (sectionGroup && Array.isArray(sectionGroup.sections)) {
            sectionGroup.sections.sort((leftSide: ProposalSection, rightSide: ProposalSection) =>
                leftSide.sortOrder - rightSide.sortOrder
            );
        }
    }

    onContentUploadModalCancel() {
        this.showFileUploadModal = false;
    }

    onContentUploadModal() {
        this.showFileUploadModal = true;
    }

    updateAdvisorContent(response) {
        this.showFileUploadModal = false;
        const document = response.data[0].documents[0];
        const proposalSection: ProposalSection = {
            id: null,
            statementSectionId: SectionId.AdvisorCustomContent,
            name: document.truncatedName,
            include: true,
            toggle: true,
            pinned: false,
            hidden: false,
            isCollapsed: true,
            sortOrder: 1,
            level: 2,
            sectionContent: {
                contentId: document.advisorCustomContentId,
                contentType: {
                    contentTypeId: ContentTypeId.PDF,
                    name: ContentTypeName.PDF
                },
                contentSource: document.documentPath,
                documentId: document.documentId
            },
            inputProperty: undefined,
            sections: [],
            isActive: true
        };
        const sectionIndex = this.statement.sections.findIndex((section: ProposalSection) => section.name === 'Advisor Content');
        this.statement.sections[sectionIndex].sections = [proposalSection, ...this.statement.sections[sectionIndex].sections];
        for (let i = 0; i < this.statement.sections[sectionIndex].sections.length; i++) {
            this.statement.sections[sectionIndex].sections[i].sortOrder = i + 1;
        }

        this.saveAdvisorContent.emit(false);
    }

    public onRenameOptionSelected(isOptionSelected: boolean): void {
        this.isRenameSelected = isOptionSelected;
    }

    public onRenameCustomContent(): void {
        this.saveAdvisorContent.emit(true);
    }

    public onDelete(event: string): void {
        this.onDeleteSection.emit(event);
    }
}
