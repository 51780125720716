/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

/* eslint-disable */

import { InjectionToken } from '@angular/core';
import { GoalAssignment } from '@CarInterfaces/account-goal';
import { GoalType } from '@CarInterfaces/goal-type';
import { CoverPageThemeIds, CoverPageThemeLabels, SelectOption } from '@sei/advisor-client-review-proposal-ux';
import { AxisTypeValue, DashStyleValue, OptionsLayoutValue, SeriesArearangeOptions, SeriesLineOptions } from 'highcharts';

export const ENVIRONMENT: InjectionToken<any> = new InjectionToken<any>('Application Environment');

export class DelegationConstants {
    public static readonly GENERAL_OWNER_INFORMATION_NAME: string =
        'Account Owner';
    public static readonly LEGAL_MAILING_ADDRESS_NAME: string =
        'Legal Mailing Address';
    public static readonly REGULATORY_INFORMATION_NAME: string =
        'Required Regulatory Information';
    public static readonly BENEFICIARY_INFORMATION_NAME: string =
        'Name Information';
    public static readonly RELATED_PARTIES_NAME: string =
        'Related Parties Information';
    public static readonly RELATED_PARTIES_ADDRESS: string =
        'Related Parties Address Information';
    public static readonly RELATED_PARTIES_ADDITIONAL: string =
        'Related Parties Additional Information';
    public static readonly POWERS_OF_ATTORNEY_AGENT_INFORMATION: string =
        'Agent Information';
    public static readonly POWERS_OF_ATTORNEY_CONTACT_INFORMATION: string =
        'Contact Information';
    public static readonly POWERS_OF_ATTORNEY_REGULATORY_INFORMATION: string =
        'Required Regulatory Information';

    public static readonly GENERAL_OWNER_INFORMATION: string =
        'Complete full name, date of birth and taxpayer ID at signing';
    public static readonly LEGAL_MAILING_ADDRESS: string =
        'Complete legal address at signing';
    public static readonly REGULATORY_INFORMATION: string =
        'Completed regulatory information at signing';
    public static readonly BENEFICIARY_INFORMATION: string =
        'Complete full name at signing';
    public static readonly POWERS_OF_ATTORNEY_INFORMATION: string =
        'Complete full name, address, date of birth, taxpayer ID, phone number and required regulatory information at signing';
    public static readonly RELATED_PARTIES_INFORMATION: string =
        'Complete full name, address, date of birth, phone number, email and taxpayer ID at signing';
}

export class AssetTransitConstants {
    public static readonly ADD_FROM_PARTIAL_LABEL: string =
        'Select from My Partial Transfers';
    public static readonly ADD_MANUALLY_LABEL: string = 'Add Manually';
}

export class RelatedPartiesConstants {
    public static readonly DEFAULT_TITTLE: string = 'Related Parties';
    public static readonly NO_DATA_ERROR: string =
        'Related Parties is not configured for this account type';
}

export class ElasticSearchConstants {
    public static readonly SEARCH_CAPTION: string = 'Search';
    public static readonly CREATE_CAPTION: string = 'Create';
    public static readonly NO_VALID_OWNERS: string = 'No owners available for this account type'
    public static readonly NO_RESULTS_FOUND: string = 'No results were found'
}

export class GoalsConstants {
    public static readonly defaultSelection: GoalType = {
        goalTypeDescription: 'Select one',
        goalTypeId: -1
    };
    public static readonly settingOptionAccountLevel: GoalAssignment = {
        goalSettingOptionId: 1,
        goalSettingDescription: 'Set the goal at the account level'
    };
    public static readonly settingOptionStrategyLevel: GoalAssignment = {
        goalSettingOptionId: 2,
        goalSettingDescription: 'Assign the goal to one or more strategies'
    };
}

export class GeneralLabelConstants {
    public static readonly email: string = 'Email';
    public static readonly legalAddress: string = 'Legal Address';
    public static readonly mailingAddress: string = 'Mailing Address';
}


export class WalkMeConstants {
    public static readonly WALKME_URL_DEFAULT = '@walkme.url@';
    public static readonly WALKME_TEST_URL = 'c5f2415b87e44957baa16a024a490f3e/test/walkme_c5f2415b87e44957baa16a024a490f3e';
    public static readonly WALKME_SNIPPET: string = '\https://cdn.walkme.com/users/@walkme.url@_https.js';
}

export class WarningModalConstants {
    public static readonly MODAL_STATE_INVESTMENT_PROGRAM: string = 'Investment Program';
    public static readonly MODAL_STATE_ACCOUNT_TYPE: string = 'Account Type';
    public static readonly MODAL_MESSAGE_ACCOUNT_TYPE: string =
        'Changing to the selected Account Type will reset the selected Investment Program and any selected investments on this account. ' +
        'Do you want to continue?';
    public static readonly MODAL_MESSAGE_INVESTMENT_PROGRAM: string =
        'Changing Investment Program will reset any selected investments on this account. Do you want to continue?';
    public static readonly MODAL_MESSAGE_ACCOUNT_CARD_RESET: string =
        'Any changes previously made to this account will be reset. Do you want to continue?';
    public static readonly MODAL_MESSAGE_UNSELECTED_ACCOUNTS: string =
        'You have unselected accounts that were previously added to this proposal. If you choose to continue, they will be removed. ' +
        'Do you want to continue?';
    public static readonly MODAL_MESSAGE_CLEAR_ALL_EXISTING_ACCOUNT_MODIFICATIONS: string = 'By modifying account owners, any accounts previously added to this proposal may be reset. Do you want to continue?';
    public static readonly MODAL_MESSAGE_STALE_OR_MISSING_ASSET_DATA: string = 'Historical pricing for this asset is unavailable. ' +
        'If you would like to add this asset, the current account must be refreshed with updated market value and price data and any previous modifications to the account will be lost. Would you like to continue?'
    public static readonly MODAL_MESSAGE_GENERIC_REFRESH: string = 'By refreshing, proposal details related to existing accounts will be reset. Are you sure you want to continue?';
}

export class TooltipConstants {
    public static readonly CLIENT_DIRECTED_MESSAGE = 'Client Directed excluded from Advisor Fee.';
    public static readonly PROPOSAL_CHECKLIST_LOCKED_MESSAGE = 'Changes cannot be saved for proposals in Archived or Implemented status.' +
        ' To make changes, please reactivate the proposal.';
    public static readonly PROPOSAL_REQUIRES_FORCE_REFRESH_TO_SAVE = 'Changes cannot be saved for this proposal. In order to continue, please return to the checklist and refresh the accounts.'
    public static readonly PROPOSAL_REQUIRES_EXISTING_ACCOUNT_FORCE_REFRESH = 'Generation is unavailable. In order to continue, please refresh the accounts using the button at the top of this page.'
    public static readonly PROPOSAL_GENERATION_LOCKED_MESSAGE = 'Generation is not available on proposals in Archived or Implemented status. To make changes, please reactivate the proposal.';
    public static readonly ACCOUNT_NAME_OVER_MAX_LENGTH = 'Account name is over maximum allowable characters and has been truncated.';
    public static readonly EXISTING_ACCOUNT_EMPTY_PORTFOLIO = 'This account has a non model portfolio with a $0 market value. ' +
        'If this account is added to the proposal, the non model portfolio will not be included.';
    public static readonly EXISTING_ACCOUNT_PRIMARY_ADVISOR = 'This account is associated with another primary advisor. It can not be added to this proposal.'
    public static readonly EXISTING_ACCOUNT_NOT_ELIGIBLE = 'The investments in this account could not be loaded. This account cannot be added to the proposal at this time.'
    public static readonly EXISTING_ACCOUNT_NEGATIVE_CASH = 'This account contains at least one cash allocation with a negative balance. By adding this account to the proposal, the balance for the negative cash allocation will be resolved to $0.01.';
    public static readonly EXISTING_ACCOUNT_NEGATIVE_NON_CASH = 'This account contains an allocation to an investment with a negative balance. This account cannot be added to the proposal at this time.';
    public static readonly ZERO_MARKET_VALUE = 'Accounts with a $0 market value cannot be added to a proposal.';
}

export class ImplementProposalConstants {
    public static readonly VALID_CHARACTER_REGEX_FOR_DAO: RegExp = /[^a-zA-Z0-9!@#^&()[\]\-_. ,\/\"\']*/g;
    public static readonly GENERIC_IMPLEMENT_SERVICE_ERROR_MESSAGE: string = 'Request to implement has failed. Please try to submit again or close the window and try again later.';
    public static readonly IMPLEMENT_SERVICE_MAX_ERROR_MESSAGE: string = 'Request to implement has failed. Please cancel out of this pop-up or close and try to implement again from the dashboard.';
    public static readonly NOT_GENERATED_PROPOSAL_INFORMATION_MESSAGE: string = 'Documents cannot be generated for proposals that are in implemented or archived status. Please ensure you generate prior to implementing if a proposal and/or IPS is required.';
    public static readonly ONLY_EXISTING_ACCOUNTS_INFORMATION_MESSAGE: string = 'The accounts in this proposal are already open and active on our platform. No changes will be made to these accounts upon implementation.';
    public static readonly BOTH_ACCOUNT_CATEGORIES_INFORMATION_MESSAGE: string = 'This proposal contains accounts that are already open and active on our platform. No changes will be made to these accounts upon implementation.'
}

export class BannerMessageConstants {
    public static readonly PROPOSAL_LOCKED_INFO_MESSAGE: string = 'Changes cannot be saved for proposals in Archived or Implemented status.';
    public static readonly MODEL_NOT_FOUND_MESSAGE: string = 'The account has models assigned that cannot be displayed.';
    public static readonly GET_HOLDINGS_CALL_FAILED: string = 'Existing accounts for one or more of the owners in this proposal could not be retrieved. Please try again later.';
    public static readonly CURRENT_ACCOUNT_GENERIC_REFRESH_MESSAGE: string = 'An update is required for this proposal. In order to continue, all current accounts must be refreshed to their original state. Do you wish to refresh your accounts?';
    public static readonly CURRENT_ACCOUNT_ASSET_PRICING_MISSING: string = 'Historical pricing for one of the assets in this proposal is unavailable. ' +
        'If you would like to continue with this proposal, the current account(s) must be refreshed with updated market value and price data and any previous modifications to the account will be lost. ' +
        'Click refresh to continue.';
}

export class HighchartsConstants {
    public static readonly DFS_DRAWDOWN_X_AXIS_TITLE: string = 'Projected Portfolio Value';
    public static readonly DFS_DRAWDOWN_AXIS_TYPE: AxisTypeValue = 'linear';
    public static readonly DFS_DRAWDOWN_AXIS_LINE_WIDTH: number = 2;
    public static readonly DFS_DRAWDOWN_AXIS_GRID_LINE_WIDTH: number = 1;
    public static readonly DFS_DRAWDOWN_GRID_LINE_DASH_STYLE: DashStyleValue = 'LongDash';
    public static readonly DFS_DRAWDOWN_Y_AXIS_TITLE: string = 'Projected Time Horizon (years)';
    public static readonly DFS_DRAWDOWN_Y_AXIS_MIN: number = 0;
    public static readonly DFS_DRAWDOWN_AVERAGE_SERIES_LABEL: string = 'Average Markets (50% Confidence)';
    public static readonly DFS_DRAWDOWN_SERIES_TYPE_LINE: SeriesLineOptions = { type: 'line' };
    public static readonly DFS_DRAWDOWN_SERIES_TYPE_RANGE: SeriesArearangeOptions = { type: 'arearange' };
    public static readonly DFS_DRAWDOWN_LINE_Z_INDEX: number = 1;
    public static readonly DFS_DRAWDOWN_RANGE_Z_INDEX: number = 0;
    public static readonly DFS_DRAWDOWN_CHARTED_LINE_DASH_STYLE: DashStyleValue = 'LongDash';
    public static readonly DFS_DRAWDOWN_AVERAGE_LINE_COLOR: string = '#66953D';
    public static readonly DFS_DRAWDOWN_DIST_CONFIDENCE_LINE_COLOR: string = '#FF8A00';
    public static readonly DFS_DRAWDOWN_DIST_CONFIDENCE_SERIES_LABEL: string = 'Distribution Confidence (80% Confidence)';
    public static readonly DFS_DRAWDOWN_POOR_MARKET_LINE_COLOR: string = '#91140F';
    public static readonly DFS_DRAWDOWN_POOR_MARKET_SERIES_LABEL: string = 'Poor Markets (99% Confidence)';
    public static readonly DFS_DRAWDOWN_POOR_MARKET_LINE_DASH_STYLE: DashStyleValue = 'ShortDash';
    public static readonly DFS_DRAWDOWN_RANGE_SERIES_LABEL: string = 'Range';
    public static readonly DFS_DRAWDOWN_RANGE_SERIES_LINE_WIDTH: number = 0;
    public static readonly DFS_DRAWDOWN_RANGE_SERIES_COLOR: string = '#173B6B';
    public static readonly DFS_DRAWDOWN_RANGE_SERIES_OPACITY: number = 0.25;
    public static readonly DFS_DRAWDOWN_RANGE_SERIES_LINKED_TO: string = ':previous';
    public static readonly DFS_DRAWDOWN_LEGEND_LAYOUT: OptionsLayoutValue = 'vertical';

}

export class NonModelOverrideSellsOnly {
    public static readonly WARNING_TEXT: string = 'The primary advisor on this proposal is subject to broker-dealer restrictions on purchasing certain types of assets through SEI\'s platform. Therefore, you must ensure that any assets that cannot be purchased on SEI\'s platform for the portfolio are received in-kind.';
}

export class PresentationThemeConstants {
    public static readonly THEME_OPTIONS: SelectOption[] = [
        {
            value: String(CoverPageThemeIds.Abstract),
            label: CoverPageThemeLabels.Abstract
        },
        {
            value: String(CoverPageThemeIds.Simplistic),
            label: CoverPageThemeLabels.Simplistic
        },
        {
            value: String(CoverPageThemeIds.Custom),
            label: CoverPageThemeLabels.Custom
        }
    ]
}
