/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserLevelEntitlement } from '@sei/ias-applications-lib-ux';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Proposal } from '../model/proposal';
import { UserProfile } from '../model/user-profile';
import { ProposalService } from './proposal.service';
import { GenericErrorService } from './system/generic-error.service';
import { UserProfileService } from './user-profile.service';

@Injectable({
    providedIn: 'root'
})
export class ProposalResolverService implements OnDestroy {

    subscriptions: Subscription[] = [];
    isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        private proposalService: ProposalService,
        private userProfileService: UserProfileService,
        private genericErrorService: GenericErrorService,
        private spinnerService: SpinnerService,
        private userProfileRepository: UserProfileRepository,
        private existingAccountsService: ExistingAccountsService

    ) { }

    resolve(route: ActivatedRouteSnapshot): void {
        this.isLoading.next(true);
        const proposalId = route.params['proposalId']
            ? +route.params['proposalId']
            : +route.parent.params['proposalId'];

        this.subscriptions.push(this.proposalService.getProposalById(proposalId)
            .pipe(tap(() => {
                this.spinnerService.start();
                this.genericErrorService.clearGenericError();
            }))
            .subscribe({
                next: (responseProposal: Proposal) => {
                    if (responseProposal) {
                        const userHasAccess = this.userProfileService.verifyAccess(responseProposal);
                        if (!userHasAccess) {
                            this.genericErrorService.setGenericError({
                                code: '403',
                                description: `Current user does not have entitlements to the proposal: ${proposalId}`
                            });
                        } else {
                            const userProfile: UserProfile = this.userProfileService.getCurrentUserProfile;
                            const isInstanceUser: boolean = userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
                                userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;
                            const userProfileFirmNotSet = !userProfile.firm;
                            if (isInstanceUser && userProfileFirmNotSet) {
                                const primaryAdvisorForProposal = responseProposal?.scenarios[0]?.accounts[0]?.advisors[0]?.entityId ||
                                    responseProposal?.advisors[0]?.entityId;
                                this.subscriptions.push(this.userProfileRepository.read(primaryAdvisorForProposal)
                                    .subscribe((userProfile: UserProfile) => {
                                        this.userProfileService.updateCurrentUserProfileFirm(userProfile.firm);
                                        this.checkIfClientCallIsNeeded(responseProposal);
                                        this.proposalService.changedProposal(responseProposal);
                                        this.isLoading.next(false);
                                        this.spinnerService.stop();
                                    },
                                        (error) => {
                                            this.genericErrorService.setGenericError(error);
                                            this.isLoading.next(false);
                                            this.spinnerService.stop();
                                        }));
                            } else {
                                this.proposalService.changedProposal(responseProposal);
                                this.isLoading.next(false);
                                this.spinnerService.stop();
                            }
                        }
                    }
                },
                error: (error) => {
                    this.genericErrorService.setGenericError(error);
                    this.isLoading.next(false);
                    this.spinnerService.stop();
                },
                complete: () => {
                    this.spinnerService.stop();
                }
            }));
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
        }
    }

    private checkIfClientCallIsNeeded(incomingProposal: Proposal): void {
        const clientIdsOnProposal: number[] = this.existingAccountsService.retrieveClientIdListFromProposal(incomingProposal);
        const currentProposal: Proposal = this.proposalService.getCurrentProposal();
        const sameProposal: boolean = incomingProposal?.id === currentProposal?.id;
        if (incomingProposal && clientIdsOnProposal?.length && !sameProposal) {
            this.existingAccountsService.isClientCallNeeded.next(true);
        }
    }
}
