/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
export enum InvestmentProgramType {
    MutualFunds = 1,
    ManagedAccounts = 2,
    DistributionFocusedStrategies = 3,
    ETF = 4,
    FixedIncome = 5,
    CustomHighNetWorth = 6,
    Custom = 999,
}

export enum ElasticControlType {
    Entity = 'Entity',
    CounterParty = 'CounterParty',
    PendingAccounts = 'PendingAccounts',
    Accounts = 'Accounts',
    Contacts = 'Contacts',
    Clients = 'Clients'
}

export enum ContactTypes {
    Individual = 1,
    Organization = 2
}

export enum ContactTypesDescription {
    Individual = 'Individual',
    Organization = 'Organization'
}

export enum OptionActions {
    None,
    Change = 1,
    Delete = 2
}

export enum OptionSelectorTextType {
    Sublabel = 1,
    RadioSelection = 2
}

// collides with interface AddressType
export const enum AddressTypes {
    Legal = 1,
    Mailing
}

export enum OwnerRole {
    None,
    Primary,
    Individual,
    Joint
}

export enum OwnerRoleDescription {
    None = 'None',
    Primary = 'Primary',
    Individual = 'Individual',
    Joint = 'Joint'
}

export enum JointOwner {
    Min = 1,
    Max = 5
}

export enum OwnerCitizenshipType {
    CitizenAndLegal = 1,
    CitizenAndNotLegal = 2,
    NotCitizen = 3
}

export enum FundingSourceView {
    InitView,
    EditView,
    CurrentFundingSourceList
}

// collides with interface FundingSourceType
export enum FundingSourceTypes {
    None = 0,
    Check = 1,
    Ach = 2,
    Wire = 3,
    Transfer = 4
}

export enum FundingSourceTransferTypes {
    FullLiquidation = 1,
    FullInKind = 2,
    Partial = 3
}

export enum AchFrequencyType {
    None = 0,
    OneTime = 1,
    Weekly,
    Monthly,
    Quarterly
}

export enum AchQuaterly {
    MinDate = 0,
    MaxDate = 28
}

export enum FundingDirectionType {
    Cash = 1,
    NoCash = 2
}

export enum PrevailingAssumptionTypes {
    Strategy = 1,
    AssetTransit = 2,
    AdvisorGuided = 3,
    ClientDirected = 4,
    FundStrategyAallocation = 5,
    Dca = 6
}

export enum AssetTransitAddType {
    FromPartial = 1,
    Manually = 2
}

// Suffixing these with s, there is variable collisions
export enum DcaSceduleTypes {
    TotalSumByTarget = 1,
    FixedContributionSpecifiedFrequency = 2
}

export enum DcaStartDateTypes {
    SpecificDate = 1,
    ImmediatlyReceiptAssets = 2
}

export enum DcaContributionFrequencyTypes {
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}

export enum DcaContributionFrequencyAmountTypes {
    None = 0,
    Dollar = 1,
    Months = 2
}

export enum PaymentOfAdvisorFeesOptions {
    PerFlatRate = 1,
    PerFeeSchedule = 2,
    NoAutomatic = 3
}

export enum PayToRecipientDistributionOptions {
    Wire = 1,
    Ach = 2
}

export enum PercentageOrBpsOptions {
    Percentage = 'percentage',
    Bps = 'bps'
}
export enum FeeTypesOptions {
    Percentage = 0,
    Bps = 1
}

export enum FeeScheduleOptions {
    Annual = 'Annual',
    OneTime = 'One-Time',
    Create = 'Create'
}

export enum FeeScheduleTieredBanded {
    Tiered = 'Tiered',
    Banded = 'Banded'
}

export enum FeeScheduleTypes {
    Tiered = 1,
    Banded = 2
}
export enum AmountOptions {
    Percentage = 'Percentage',
    Dollars = 'Dollars'
}

export enum NumberFormats {
    None = 0,
    Numeric = 1,
    Currency = 2
}

export enum CurrencyTypes {
    USD = 'USD',
    MXN = 'MXN',
}

export enum DateEnum {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

// ACCOUNT GOALS
export enum WipGoalSettingOption {
    None = 0,
    AtAccountLevel = 1,
    NotAtAccountLevel = 2
}
// ACCOUNT GOALS

export enum WipDirection {
    None = 0,
    FromInitialToFinal = 1,
    FromFinalToInitial = 2
}

export enum PaymentInformationDistribution {
    FromThisAccount = 1,
    NotFromThisAccount = 2
}

export enum AccountTypeDistributionViaCheck {
    Checking = 1,
    Savings = 2
}

export enum DistributionViaCheckOptions {
    Overnight = 1,
    ByWireACH = 2
}

export enum WithholdingElection {
    WithIncome = 1,
    WithoutIncome = 2
}

export enum DistributionTypeOptions {
    Wire = 1,
    Ach = 2
}

export enum AnnualInflation {
    Adjust = 1,
    NotAdjust = 2
}

export enum AdvisorRole {
    Primary = 1,
    Additional = 2,
}

export const advisorLabel = new Map<number, string>([
    [AdvisorRole.Primary, 'Primary'],
    [AdvisorRole.Additional, 'Additional']
]);

export enum FileUploadViewMode {
    AccountOwner = 1,
    PendingAccounts = 2,
    CoverImage = 3,
    BrandLogo = 4,
    AdvisorContent = 5
}

export enum FileUploadEditSection {
    NewAccount = 1,
    MaintainDocument = 2,
    AdvisorAdmin = 3
}

export enum FileUpload {
    MaxFilesAllowedByUpload = 1
}

export enum FileUploadDocumentTypes {
    BusinessLicense = 1,
    PartnershipAgreement = 2,
    DriverLicense = 3,
    Passport = 4,
    GreenCard = 5,
    TrustDocument = 6,
    CourtCertifiedCopyTestamentary = 7,
    ArticlesIncorporation = 8,
    CertificateIncorporation = 9,
    IrsDetermination = 10,
    CourtOrder = 11,
    OtherGovernmentIssuedId = 12,
    Others = 13
}

export enum DelegationSectionType {
    GeneralOwnerInformation = 1,
    LegalMailingAddress = 2,
    RegulatoryInformation = 3,
    BeneficiaryInformation = 4,
    RelatedPartiesInformation = 5,
    PowersOfAttorneyInformation = 6
}

export enum AssetControlMode {
    NumberOfShares = 'NumberOfShares',
    PurchaseAmount = 'PurchaseAmount'
}

export enum RiskToleranceQuestionInsertImage {
    QuestionNumberThree = 3,
    QuestionNumberTen = 10,
    QuestionImageName = 'rtq-question-three.png'
}

export enum RightHandFooterFunctions {
    EditGoal = 'WIP-EditGoal',
    Account = 'WIP-Account',
    Fee = 'WIP-Fee',
    EditProposal = 'WIP-EditProposal',
    EditScenario = 'WIP-EditScenario',
    Proposal = 'PROPOSAL',
    EditProposedAccounts = 'WIP-ProposedAccounts',
    EditExistingAccounts = 'WIP-ExistingAccounts',
    SelectExistingAccounts = 'WIP-SelectExistingAccounts'
}

export enum CommandText {
    ClearService = '',
    AddAdditional = 'Add Additional',
    AddReturn = 'Add Return',
    ProposeScenario = 'Propose Scenario',
    EnableScenario = 'Enable Scenario',
    DisableScenario = 'Disable Scenario',
    CreateProposal = 'Create Proposal',
    ModifyProposalScenario = 'Modify Proposal Scenario',
    EnableAdditionalProposedAccount = 'Enable Additional Proposed Account',
    DisableAdditionalProposedAccount = 'Disable Additional Proposed Account',
    UpdateBalance = 'Update Balance',
    EnableCreateProposal = 'Enable Create Proposal',
    DisableCreateProposal = 'Disable Create Proposal',
    EnableModifyProposalScenario = 'Enable Modify Proposal Scenario',
    DisableModifyProposalScenario = 'Disable Modify Proposal Scenario',
    GetStrategies = 'Get Strategies',
    UpdateStrategy = 'Strategy Updated',
    ChangeStrategy = 'Change Strategy',
    SaveStrategies = 'Save Strategy',
    ActivateSection = 'Activate Section',
    UpdateWipCheckList = 'Update Wip CheckList',
    SaveFeeGroup = 'Save Fee Group',
    ChangeRiskCapacity = 'Change Risk Capacity',
    GetFeeStrategy = 'Get Fee Strategy',
    ExitAccountPanel = 'Exit And Return',
    DisableSaveAndExitButton = 'Disable Save And Exit Button',
    EnableSaveAndExitButton = 'Enable Save And Exit Button',
    ValidateFeeTierAmount = 'ValidateFeeTierAmount',
    CancelStrategyBalance = 'Cancel Strategy Balance',
    GoToNext = 'Go To Next',
    GoToPrev = 'Go To Prev',
    DisableNextButton = 'Disable Next Button',
    EnableNextButton = 'Enable Next Button',
    DisablePrevButton = 'Disable Prev Button',
    EnablePrevButton = 'Enable Prev Button',
    SaveEditProposal = 'Save Edit Proposal',
    SaveEditScenario = 'Save Edit Scenario',
    SaveGoal = 'Save Goal',
    CancelGoal = 'Cancel Goal',
    MrdcCanceled = 'Mrdc Canceled',
    UpdateRiskWidget = 'Update Risk Widget',
    RefreshFees = 'Refresh Fees',
    ImplementProposal = 'Implement Proposal',
    CancelRightHandDialog = 'Cancel save and exit',
    HideGoalSection = 'Hide Goal Section',
    DisplayGoalSection = 'Display Goal Section',
    RefreshClientList = 'Refresh Client List',
    IsLoading = 'IsLoading',
    LoadingStopped = 'LoadingStopped',
    DisableWipImplementProposalButton = 'Disable WIP Implement Proposal Button',
    EnableWipImplementProposalButton = 'Enable WIP Implement Proposal Button',
    DisableImplementModalSubmitButton = 'Disable Implement Modal Submit Button',
    EnableImplementModalSubmitButton = 'Enable Implement Modal Submit Button',
    EnableCurrentAccountsSubmitButton = 'Enable Current Accounts Submit Button',
    DisableCurrentAccountsSubmitButton = 'Disable Current Accounts Submit Button',
    NonModelEnrichmentInProgress = 'Non Model Enrichment In Progress',
    NonModelEnrichmentComplete = 'Non Model Enrichment Complete',
    DisableAddProposedAccountsButton = 'Disable Proposed Accounts Button',
    EnableProposedAccountsButton = 'Enable Proposed Accounts Button',
    AddSelectedAccounts = 'Add Selected Accounts',
    RemoveSelectedExistingAccount = 'Remove Selected Existing Account',
    AccountsFlyoutCancel = 'Accounts Flyout Cancel',
    AddSelectedAccountsButton = 'Add Selected Accounts Button',
    SaveAndExitButton = 'Save and Exit Button',
    AddOwnerToProposal = 'Add Owner to Proposal',
    ExistingAccountsRefreshCompleted = 'Existing Accounts Refresh Completed',
    CancelExistingAccountsPickListSelections = 'Cancel Existing Accounts Pick List Selections',
    ExistingAccountRefreshCompletedFromAccountDetails = 'Existing Accounts Refresh Completed from Account Details'
}

export enum HUDSummaryLabel {
    MgmtDFS = 'Mgmt & DFS Fee',
    MgmtFee = 'Mgmt Fee',
    FundExp = 'Fund Exp',
    PlatformFee = 'Platform Fee',
}
export enum DatePickerType {
    DatePicker = 'Datepicker',
    DateRangePicker = 'Daterangepicker'
}

export enum FirstDollarProspectus {
    Email = 1,
    LegalAddress = 2
}

export enum EventType {
    MouseOver = 'mouseover'
}

export enum ProxyStatements {
    email = 1,
    legalAddress = 2,
    mailingAddress = 3
}

export enum DeliveryStatementsAndPaytos {
    mailingAddress = 1,
    legalAddress = 2
}

export enum TypeOfOwnerId {
    IndividualAdult = 1,
    IndividualMinor = 2,
    Trust = 3,
    Estate = 4
}

export enum AccountAdvisorsDropdownLabels {
    Primary = 'Primary Advisor',
    Additional = 'Additional Advisor'
}

export enum IndividualOrganization {
    Individual = 1,
    Organization = 2
}

export enum AccountTypes {
    Qualified = 'Qualified Accounts',
    NonQualified = 'Non Qualified Accounts',
    NonSeiAccounts = 'Aggregated Accounts',
    SeiAccounts = 'SEI Accounts',
    Fees = 'Fees'
}

export enum AccountOwnerDropdownStaticOptions {
    NewClient = 'New Client',
    StartTypingName = 'Start typing a name',
    HorizontalLine = '────────────'
}

export enum Expand {
    More = 'More',
    Less = 'Less',
}

export enum WipChecklistLabels {
    ClientRiskPreference = 'Client Risk Preference',
    AssumptionsTransitionAnalysis = 'Assumptions for Tax Transition Analysis',
    CurrentScenario = 'Current Scenario',
    ProposalScenario = 'Proposal Scenario',
    FeeAnalysis = 'Fee Analysis for Me',
    ScenarioGoal = 'Scenario Goal',
    CurrentAccounts = 'Current Accounts',
    TaxLots = 'TaxLots',
    ProposedAccounts = 'Proposed Accounts',
    ExistingAccounts = 'Existing Accounts',
    Accounts = 'Accounts'
}

export enum WipScenarioTypes {
    Proposal = 'Proposed',
    Current = 'Current'
}

export enum ScenarioTypes {
    Proposal = 1,
    Current = 2
}

export enum WipChangeState {
    PartialTransition = 'Partial Transition',
    New = 'New',
    Completed = 'Completed',
}

export enum StrategyMenu {
    ExploreStrategy = 'Explore Investment',
    PreviouslyUsed = 'Previously Used',
    CustomizeStrategy = 'Customize Investment',
    StrategySelection = 'Investment Selection',
    StrategySummary = 'Investment Summary',
}

export enum WipAccountStatus {
    New = 0,
    Complete = 1,
    Incomplete = 2
}

export enum WipTagNames {
    New = 'New',
    Change = 'Change',
    Maintain = 'Maintain',
    FullTransition = 'Full Transition',
    PartialTransition = 'Partial Transition',
}

export enum ActionTags {
    New = 4,
    Change = 3,
    Maintain = 0,
    FullTransition = 1,
    PartialTransition = 2,
}

export enum RiskWidgetTypes {
    StrategySelection = 'Investment Selection',
    StrategySummary = 'Investment Summary',
    ExploreStrategy = 'Explore Investment'
}

export enum RiskLevels {
    None = 'N/A',
    LowIndex = 0,
    Low = 'Low Risk',
    LowLow = 1,
    LowMedium = 2,
    LowHigh = 3,
    MediumIndex = 1,
    Medium = 'Medium Risk',
    MediumLow = 4,
    MediumMedium = 5,
    MediumHigh = 6,
    HighIndex = 2,
    High = 'High Risk',
    HighLow = 7,
    HighMedium = 8,
    HighHigh = 9,
}

export enum RiskLevelClasses {
    NoneClass = '_0',
    LowLowClass = '_1',
    LowMediumClass = '_2',
    LowHighClass = '_3',
    MediumLowClass = '_4',
    MediumMediumClass = '_5',
    MediumHighClass = '_6',
    HighLowClass = '_7',
    HighMediumClass = '_8',
    HighHighClass = '_9',
    RiskLowClass = '_low',
    RiskMediumClass = '_med',
    RiskHighClass = '_high',
}

export enum RiskLevelValues {
    LowMin = 0,
    LowMid = 16,
    LowMax = 31.99,
    MediumMin = 32,
    MediumMid = 50.5,
    MediumMax = 68.99,
    HighMin = 69,
    HighMid = 84.5,
    HighMax = 100
}

export enum RiskToleranceLevel {
    Low = 2,
    Medium = 5,
    High = 8,
    Skip = 3
}

export enum OptionRiskLevel {
    Questionnaire = 1,
    LowMediumHigh = 2,
    Skip = 3
}

export enum RiskMethods {
    Questionnaire = 1,
    LowMediumHigh = 2,
    Skip = 3
}

export enum RiskToggleOptions {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Skipped = 'Skipped',
    Incomplete = 'Incomplete'
}

export enum AccountDetailTabs {
    RiskCapacity = 1,
    Transition = 2,
    StrategySelection = 3,
    OtherInvestments = 4
}

export enum InvestmentProgramTypes {
    MutualFund = 1,
    ManagedAccountSolution = 2,
    DistributionFocused = 3,
    ETF = 4,
    FixedIncome = 5,
    CustomHighNetWorth = 666666,
}

export enum AlertPopoverTypes {
    Error = 'Error',
}

export enum HtmlElementType {
    DropDown = 'dropdown',
    TextBox = 'textbox',
}

export enum ProposalSectionLabel {
    CoverPage = 'Cover Page',
    CoverImage = 'Cover Image',
    CoverTitle = 'Cover Title',
    TitleBarColor = 'Title Bar Color',
    TitleBarTextColor = 'Title Bar Text Color',
    BrandLogo = 'Brand Logo',
    ReportingMethod = 'Reporting Method',
    ReportingLevel = 'Reporting Level',
    ShowAdvisorFee = 'Show Advisor Fee?',
    ChartType = 'Chart Type',
    ReturnsBy = 'Returns By',
    ProposedScenario = 'Proposed Scenario',
    ProposalSummary = 'Proposal Summary',
    BenefitsOfaGoalsDrivenApproach = 'Benefits of a Goals-Driven Approach',
    YourVisionOfFinancialWellness = 'Your Vision of Financial Wellness',
    GoalsBasedSummary = 'Goals-Based Summary',
    NonGoalsBasedSummary = 'Non-Goals-Based Summary',
    SeiStrategyDetails = 'SEI Strategy Details',
    Proposal = 'Proposal',
    IPS = 'IPS',
    EstimatedCostOverview = 'Estimated Cost Overview',
    DFSDrawdownByAccount = 'DFS Drawdown by Account',
    ReportingLevels = 'Reporting Levels',
    ReportOptions = 'Report Options',
    AdvisorContent = 'Advisor Content',
    ReturnProjectionUpperAndLowerBoundary = 'Return Projection Upper and Lower Boundary',
    Analytics = 'Analytics',
    MarketIndex = 'Market Index',
    TableOfContents = 'Table of Contents',
    PresentationTheme = 'Presentation Theme',
    CoverSubtitle = 'Cover Subtitle',
    ThemeColor = 'Theme Color',
    VerticalAccentBar = 'Vertical Accent Bar'
}

export enum CoverPageThemeLabels {
    Abstract = 'Abstract',
    Simplistic = 'Simplistic',
    Custom = 'Custom'
}

export enum CoverPageThemeIds {
    Abstract = 1,
    Simplistic = 2,
    Custom = 3
}

export enum ProposalStatementSectionIds {
    // unique id's of individual sections.
    ProposalCoverPage = 3,
    ProposalCoverTitle = 12,
    ProposalCoverImage = 11,
    IPSCoverImage = 45,
    IPSCoverPage = 44
}

export enum ProposalStatementType {
    Proposal = 1,
    IPS = 2,
}

export enum DocumentCreation {
    // Note: 1: single page, 2: seperate documents - this will be default, 3: single document per scenario
    SinglePage = 1,
    SeparateDocuments = 2,
    SingleDocumentPerSenario = 3
}

export enum InputPropertyType {
    Title = 'title',
    Image = 'image',
    Color = 'color'
}

export enum EntityType {
    Prospect = 'Prospect',
    Client = 'Client',
    ThirdParty = 'Third Party',
    Firm = 'Firm',
    Advisor = 'Advisor',
}

export enum FirmAdvisorLabel {
    FirmOrAdvisor = 'Firm or Advisor Name',
    Firm = 'Firm',
}

export enum FirmAdvisorDisplayMode {
    Proposal = 'Proposal',
    Dashboard = 'Dashboard',
}

export enum UserLevelEntitlement {
    Instance = 1,
    PO = 2,
    FirmAdvisor = 3
}

export enum ElasticSearchResultType {
    Contact = 1,
    ThirdParty = 2,
    Search = 3
}

export enum HudMode {
    Questionnaire = 'Questionnaire',
    FeeSummary = 'Fees',
    Strategy = 'Strategy'
}

export enum HudInfoText {
    Questionnaire = 'Questionnaire Info',
    FeeSummary = 'Fees Info',
    StrategySummary = 'Strategy Info',
    ResetFirmDefault = 'Reset to Firm Default',
}

export enum OptionExploreStrategy {
    AssetAllocation = 1,
    InvestmentRisk = 2,
    Objective = 3,
    AllAvailable = 4
}

export enum OptionByObjectiveDropdown {
    Growth = 1,
    Stability = 2,
    Income = 3,
}

export enum FeeType {
    FlatFee = 1,
    FirmFeeSchedule = 2
}

export enum RiskSnapPoints {
    None = 0,
    LowLow = 1,
    LowMedium = 2,
    LowHigh = 3,
    MediumLow = 4,
    MediumMedium = 5,
    MediumHigh = 6,
    HighLow = 7,
    HighMedium = 8,
    HighHigh = 9
}

export enum OptionExploreStrategyDropDown {
    AssetAllocation = 'EQ/FI Allocation',
    InvestmentRisk = '',
    Objective = 'Objective',
    AllAvailable = 'Investment Program'
}

export enum OptionByObjectiveDropDown {
    Growth = 1,
    Stability = 2,
    Income = 3
}

export enum InvestmentObjective {
    Growth = 'Growth',
    Stability = 'Stability',
    Distribution = 'Distribution',
}

export enum OptionFilterStrategies {
    Suggested = 1,
    MinimizeRisk = 2,
    MaximizeReturn = 3,
    ActivelyManaged = 4,
    PassivelyManaged = 5
}

// Note: This change per Paul Flamiano 3/3/2020 to filter by these values.
export enum TaxManagementLevelValues {
    Passive = '284470001',
    Active = '284470000'
}

export enum ScenarioFeeScheduleTypes {
    SWP = 'SWP',
    Custom = 'Custom'
}

export enum FeeAnalysisName {
    AdvisorFee = 'Advisor Fee'
}

export enum ContentTypes {
    Zip = 'application/zip',
    Pdf = 'application/pdf'
}

export enum DefaultImage {
    NoImage = 'No Image',
    NoImagePath = '/presentations/images/coverpageimages/',
    NoImagePng = 'no-image.png'
}

export enum StrategyNavTabs {
    Risk = 'Risk',
    Strategy = 'Investments'
}

export enum SectionId {
    AdvisorCustomContent = 94
}

export enum ContentTypeId {
    PDF = 3
}

export enum ContentTypeName {
    PDF = 'PDF'
}

export enum ToolTipLabels {
    Rename = 'Rename',
    EditScenario = 'Edit Scenario',
    EditProposal = 'Edit Proposal',
    EditGoal = 'Rename Goal',
    RemoveScenario = 'Delete Scenario',
    RemoveProposal = 'Delete Proposal',
    RemoveGoal = 'Delete Goal',
    RemoveScenarioTitle = 'Delete Scenario?',
    RemoveProposalTitle = 'Delete Proposal?',
    RemoveGoalTitle = 'Delete Goal?',
    RemoveScenarioBody = 'Are you sure you want to remove this scenario from the proposal, you cannot undo this action.',
    RemoveProposalBody = 'Are you sure you want to remove this proposal, you cannot undo this action.',
    RemoveGoalBody = 'Are you sure you want to remove this goal, you cannot undo this action.',
    Delete = 'Delete'
}

export enum Proposals {
    NewId = 0
}

export enum DefaultSelection {
    Select = 0
}
export enum WipSections {
    Scenarios = 'Scenarios',
    AccountDetails = 'Accounts'
}

export enum ProposalStatus {
    Generated = 'Generated',
    Archived = 'Archived',
    Implemented = 'Implemented',
    Draft = 'Draft',
}

export enum ServerErrorStatus {
    ValidationError = 428
}

export enum InvestmentProgramLabels {
    MutualFundStrategies = 'Mutual Fund Strategies',
}

export enum PostProposalImplementRedirectActions {
    Dashboard = 'Dashboard',
    Account = 'Account',
    SWP = 'SWP'
}

export enum ProposalActions {
    InitialState,
}

export enum GoalSelectionOptions {
    GoalType,
    Priority,
    Frequency
}

export enum ModelTypeId {
    UMAModel = 1,
    Strategy = 2,
}

export enum TaxStatus {
    Taxable = 'Taxable',
    NonTaxable = 'Non-Taxable',
}

export enum DashboardTabOptions {
    Active = 'active',
    Archived = 'archived'
}

export enum DashboardQueryParams {
    SelectedTab = 'SelectedTab'
}

export enum CashAndEquivalents {
    CashEquivalents = 'Cash & Equivalents',
    Cash = 'Cash',
    MoneyMarket = 'Money Market'
}

export enum AllocationLevelClass {
    Cash = 11
}

export enum AssetNames {
    Equity = 'Equity',
    FixedIncome = 'Fixed Income',
    Alternative = 'Alternative',
    MultiAsset = 'Multi-Asset',
    Cash = 'Cash & Equivalents'
}

export enum YesNoLabels {
    Yes = 'Yes',
    No = 'No'
}

export enum TaxTransitionAnalysis {
    PageTitle = 'Tax Transition Analysis',
    Edit = 'Edit',
    Add = 'Add'
}

export enum ChecklistErrorMessages {
    InProgress = 'The tax transition analysis is being processed. ' +
    'Edit will be enabled when the process is fully completed.',
    Error = 'An error has occurred. Please click Edit and submit your request again.',
    Saved = 'The Tax Transition Analysis is not yet completed. Select Edit to continue.'
}

export enum SubmitButtonErrorMessages {
    GenericError = 'An error has occurred. Please try submitting again.'
    + ' If issue persists, please contact your service team at 800-734-1003.',
    GenericFailure = 'generic'
}

export enum TaxAnalysisStates {
    NotStarted = 0,
    ReportGenerated = 1,
    InProgress = 2,
    Completed = 3,
    AnalysisInException = 4,
    ReportException = 5
}

export enum AccountNameMessages {
    Empty = '',
    BothSubscribedUnsubscribedAssets = 'This account includes user provided prices and/or market value which is reflected' +
    ' in the total Market Value.',
    OnlyUnsubscribedAssets = 'This account includes only user provided prices and/or market value which is reflected' +
    ' in the total Market Value.',
    PriceAsOfMessage = 'Pricing as Of reflects prices available on the close of business for the date shown.'
}

export enum AsyncOperationType {
    Insert = 1,
    Update = 2
}

export enum PortfolioPurpose {
    ClientDirected = 1,
    AdvisorGuided = 2,
    AssetTransit = 3,
    HoldingMoneyMarket = 4,
}

export enum ProposalStatusId {
    Draft = 1,
    Archived = 2,
    Generated = 3,
    Implemented = 4
}

export enum CurrentAccountTypeMapping {
    REVOCABLE_TRUST = '34',
    IRREVOCABLE_TRUST = '34',
    FOUNDATION_ENDOWMENT = '13,15',
    CORPORATE_ACCOUNT = '27,31',
    OWNER_ONLY_QUALIFIED_PLAN = '12',
    CHARITABLE_TRUST = '34',
    IRREVOCABLE_TRUST_PTS = '34',
    CHARITABLE_REMAINDER_UNITRUST_PTS = '34',
    IRREVOCABLE_TRUST_DUE_TO_DEATH = '34',
    // RETIREMENT_OUTSIDE_PLATFORM = 'Other',
    IRREV_GST_EXEMPT_PTS = '34',
    INHERITED_IRA_PTS = '34',
    TESTAMENTARY_PTS = '34',
    TAXABLE_TOTAL_RETURN_UNITRUST_TOTAL_RETURN_UNITRUST_PTS = '34',
    LIFE_INSURANCE_IRREVOCABLE_INSURANCE_IRREVOCABLE_PTS = '34',
    NET_INCOME_MAKEUP_CRUT_INCOME_MAKEUP_CRUT_PTS = '34',
    REVOCABLE_TRUST_PTS = '34',
    CHARITABLE_LEAD_ANNUITY_TRUST_LEAD_ANNUITY_TRUST_PTS = '34',
    SPECIAL_NEEDS_TRUST_NEEDS_TRUST_PTS = '34',
    RESIDUAL_PTS = '34',
    MARITAL_PTS = '34',
    EB_OTHER_TRUST_EB = '34',
    PENSION_TRUST_EB = '34',
    RESIDUAL_GST_EXEMPT_PTS = '34',
    CHARITABLE_REMAINDER_ANNUITY_REMAINDER_ANNUITY_PTS = '34',
    NET_INCOME_GAINS_MAKEUP_CRUT_INCOME_GAINS_MAKEUP_CRUT_PTS = '34',
    // ESOP = 'ESOP',
    INSURANCE_TRUST = '34',
    WELFARE_BENEFIT_EB = '12',
    WELFARE_BENEFIT_TRUST = '34',
    CHARITABLE_LEAD_UNITRUST_LEAD_UNITRUST_PTS = '34',
    GRANTOR_RETAINED_UNITRUST = '34',
    MARITAL_GST_EXEMPT_PTS = '34',
    NON_PLAN_RABBI_TRUST = '34',
    ROTH_CONVERSION_IRA_CONVERSION_IRA_PTS = '34'
}

export enum MmtModelStatus {
    DRAFT = 'Draft',
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}
