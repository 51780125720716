/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit } from '@angular/core';
import { BannerMessageType } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { AccountOwnerDO, EditDataObject, EditTaxTransitionData } from '@sei/ias-applications-lib-ux/lib/tax-transition-analysis/model/tax-transition-analysis.model';
import { Observable } from 'rxjs';
import { HudSummary } from '../../model/account-fee';
import { AccountTypes, ChecklistErrorMessages, EntityType, TaxAnalysisStates, TaxTransitionAnalysis, WipAccountStatus, WipChecklistLabels } from '../../model/enums';
import { Account, Client, MdcSnapshot, Proposal, WipCheckList, WipCheckListCurrentAccountDisplay } from '../../model/proposal';
import { ProposalTaxTransitionService } from '../../service/proposal-tax-transition.service';
import { ProposalService } from '../../service/proposal.service';
import { GenericErrorService } from '../../service/system/generic-error.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'car-wip-checklist-details',
    templateUrl: './wip-checklist-details.component.html',
    styleUrls: ['./wip-checklist-details.component.scss']
})
export class WipChecklistDetailsComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public subCheckList: WipCheckList[];
    @Input()
    public sectionType: string;
    @Input()
    public proposalId: number;
    @Input()
    public scenarioId: number;
    @Input()
    public checkListId: number;
    @Input()
    public isCurrentAccountAddEnable?: boolean;
    @Input()
    public proposal: Proposal;
    @Input()
    public isPortfolioAssetDataLoading: boolean;
    @Input()
    public totalBalance: number;

    public wipAccountStatus: typeof WipAccountStatus = WipAccountStatus;
    public sectionLabels: typeof WipChecklistLabels = WipChecklistLabels;
    public accountTypes: typeof AccountTypes = AccountTypes;
    public wipCheckListGoals: WipCheckList;
    public seiAccountsWipCheckList: WipCheckList[] = [];
    public existingAccountsWipCheckList: WipCheckList[] = [];
    public hudSummary: Observable<HudSummary>;
    public feesWipCheckList: WipCheckList;
    public isTaxTransitionEnable: boolean = false;
    public isLoadTaxTransitionAnalysis: boolean = false;
    public accountOwners: AccountOwnerDO[] = [];
    public mainTitle: string;
    public subtitle: string;
    public editObject: EditTaxTransitionData = {
        isAddFlag: true,
        editDO: null
    };
    public addEditButton: string = TaxTransitionAnalysis.Add;
    public showErrorBanner: boolean = false;
    public showInProcess: boolean = false;
    public showSaved: boolean = false;
    public typeError: BannerMessageType = BannerMessageType.danger;
    public contentError: string = ChecklistErrorMessages.Error;
    public typeInProcess: BannerMessageType = BannerMessageType.warning;
    public contentInProcess: string = ChecklistErrorMessages.InProgress;
    public mdcSnapShot: MdcSnapshot = {
        id: 1,
        active: true,
        changeState: '',
        completed: 1,
        disabled: true,
        optional: true,
        required: true
    };
    public reportGenerated: boolean = false;
    public mdcSnapShotIncomplete: MdcSnapshot = {
        id: 2,
        active: true,
        changeState: '',
        completed: 2,
        disabled: true,
        optional: true,
        required: false
    };
    public isReadUser: boolean = false;
    public showTaxRateSaveFailedInfo: boolean = false;
    public proposedAccountsLabel: WipChecklistLabels = WipChecklistLabels.ProposedAccounts;
    public isTaxTransitionAnalysisSectionVisible: boolean = true;

    constructor(private proposalTaxTransitionService: ProposalTaxTransitionService,
        private userProfileService: UserProfileService,
        private readonly genericErrorService: GenericErrorService,
        private existingAccountsService: ExistingAccountsService,
        private featureFlagService: FeatureFlagService,
        private proposalService: ProposalService,
        private spinnerService: SpinnerService) {
        super('WipChecklistDetailsComponent');
    }

    public ngOnInit(): void {
        this.isReadUser = this.userProfileService.isReadUser;
        this.subCheckList.forEach((subSection) => {
            if (subSection.mdcSnapShot.isSEIAccount) {
                this.checkIsCurrentAccount(subSection) ?
                    this.existingAccountsWipCheckList.push(subSection) :
                    this.seiAccountsWipCheckList.push(subSection);
            }
            if (subSection.name && subSection.name === 'TaxLots') {
                this.editObject.isAddFlag = !(subSection.subCheckList.length > 0);
                this.addEditButton = this.editObject.isAddFlag ? TaxTransitionAnalysis.Add : TaxTransitionAnalysis.Edit;
                this.checkCompletionStatus();
            }
        });
        const goalChecklist: WipCheckList =
            this.subCheckList.find((checkListItem) => checkListItem.name === WipChecklistLabels.ScenarioGoal);
        this.wipCheckListGoals = goalChecklist ? goalChecklist : { id: 0 };
        this.feesWipCheckList = this.subCheckList.find((checkListItem) => checkListItem.name === WipChecklistLabels.FeeAnalysis);
        if (this.featureFlagService.checkExistingAccountsSectionEnabled()) {
            this.proposedAccountsLabel = WipChecklistLabels.Accounts;
        }
    }

    private checkCompletionStatus(): void {
        if (this.proposal && this.proposal.wipCheckList) {
            this.proposal.wipCheckList.forEach((subcheckList: WipCheckList): void => {
                if (subcheckList.name === this.proposal.name) {
                    subcheckList.subCheckList.forEach((listItem: WipCheckListCurrentAccountDisplay): void => {
                        if (listItem.name === 'TaxLots') {
                            const subListItem: WipCheckListCurrentAccountDisplay =
                                listItem.subCheckList[0] ? listItem.subCheckList[0] : null;
                            if (subListItem) {
                                const taxAnalysisStatus: number = subListItem.mdcSnapShot.completed;
                                this.showInProcess = (!this.showErrorBanner && !this.reportGenerated) &&
                                    (taxAnalysisStatus === TaxAnalysisStates.InProgress
                                        || taxAnalysisStatus === TaxAnalysisStates.Completed);
                                this.showErrorBanner = (!this.showInProcess && !this.reportGenerated) &&
                                    (taxAnalysisStatus === TaxAnalysisStates.AnalysisInException ||
                                        taxAnalysisStatus === TaxAnalysisStates.ReportException);
                                this.reportGenerated = (!this.showInProcess && !this.showErrorBanner &&
                                    taxAnalysisStatus === TaxAnalysisStates.ReportGenerated);
                                this.isTaxTransitionEnable = !this.showInProcess;
                                this.showSaved = this.isTaxTransitionEnable && this.addEditButton === TaxTransitionAnalysis.Edit
                                    && !this.showInProcess && !this.showErrorBanner && !this.reportGenerated;
                                if (this.reportGenerated || this.showSaved) {
                                    this.showTaxRateSaveFailedInfo = subListItem.isSaveTaxRatesFailed ?
                                        subListItem.isSaveTaxRatesFailed : false;
                                }
                            }
                        }
                    });
                }
            });
        }
    }

    public loadTaxTransitionAnalysis(): void {
        if (this.isTaxTransitionEnable) {
            if (this.addEditButton === TaxTransitionAnalysis.Edit) {
                this.proposalTaxTransitionService.fetchTaxTransitionDetails(this.proposalId).subscribe(
                    (response: EditDataObject): void => {
                        if (response) {
                            this.editObject = {
                                isAddFlag: false,
                                editDO: response
                            };
                        } else {
                            this.editObject = {
                                isAddFlag: true,
                                editDO: null
                            };
                        }
                        this.setWorksheetData();
                    },
                    (): void => {
                        this.editObject = {
                            isAddFlag: true,
                            editDO: null
                        };
                        this.setWorksheetData();
                    }
                );
            } else {
                this.setWorksheetData();
            }
        }
    }

    private setWorksheetData(): void {
        this.isLoadTaxTransitionAnalysis = true;
        document.body.style.overflow = 'hidden';
        this.mainTitle = TaxTransitionAnalysis.PageTitle;
        this.subtitle = this.proposal.name ? this.proposal.name : '';
        this.getAccountOwners();
    }

    public getAccountOwners(): void {
        if (this.proposal) {
            if (this.proposal.clients && this.proposal.clients.length > 0) {
                this.proposal.clients.forEach((data) => {
                    this.accountOwners.push({
                        id: data.id, name: data.firstName + ' ' + data.lastName,
                        entityId: data.entityId, entityType: data.entityType
                    });
                });
            }
        }
    }

    public showTaxTransitionModal(event): void {
        this.isLoadTaxTransitionAnalysis = event.loadTaxTransitionAnalysis;
        this.addEditButton = event.addEditButtonText;
        if (this.addEditButton === TaxTransitionAnalysis.Edit) {
            this.editObject.isAddFlag = false;
        }
        this.showErrorBanner = event.showError;
        this.showInProcess = event.showInProcess;
        this.isTaxTransitionEnable = event.isAddEnabled;
        this.reportGenerated = event.showSuccess;
        this.showSaved = event.showSaved ? (!this.showErrorBanner && !this.showInProcess && !this.reportGenerated) : false;
        if (this.reportGenerated || this.showSaved) {
            this.showTaxRateSaveFailedInfo = event.showInfo;
        }
    }

    public enableTaxTransitionAdd(event): void {
        if (this.editObject && this.editObject.isAddFlag) {
            this.isTaxTransitionEnable = event;
        } else {
            this.checkCompletionStatus();
        }
    }

    public downloadTransitionFile(): void {
        this.spinnerService.start();
        this.proposalTaxTransitionService.getGeneratedDocument(this.proposal.id, this.proposal.name)
            .subscribe(() => {
                this.spinnerService.stop();
            },
                (err) => {
                    this.genericErrorService.setGenericError({ code: '', description: `${err.message}` });
                    this.spinnerService.stop();
                });
    }

    public showExistingAccountsSection(): boolean {
        const showFeature: boolean = this.featureFlagService.checkExistingAccountsSectionEnabled();
        return showFeature && this.proposal.clients
            .some((client: Client) => client.entityType === EntityType.Client && Number(client.entityId) !== 9999);
    }

    public isChecklistLocked(): boolean {
        return this.proposalService.isProposalChecklistLocked();
    }

    private checkIsCurrentAccount(subSection: WipCheckList): boolean {
        const proposalAccountId: number = Number(subSection.mdcSnapShot.id);
        const proposalAccount: Account = this.proposal.scenarios[0]?.accounts?.find((account: Account) => account.id === proposalAccountId);
        return !!proposalAccount?.currentAccountId;
    }

    public toggleTaxTransistionAnalysisSection(): void {
        this.isTaxTransitionAnalysisSectionVisible = !this.isTaxTransitionAnalysisSectionVisible;
    }
}
